<template>
  <v-container>
    <v-row class="justify-center">
      <v-col cols="12" md="6">
        <v-card class="elevation-12 text-center rounded-xl" color="#F0F1FF">
          <v-card-title class="mt-2">
            <v-img src="../../assets/A101/logo.png" contain max-height="300"></v-img>
          </v-card-title>
          <v-card-subtitle class="mt-2">
            <h3>Ingresa con tu correo:</h3>
          </v-card-subtitle>
          <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    
                      <v-text-field 
                        v-model="email"
                        label="Email" 
                        filled
                        outlined 
                        dense
                        :rules="emailRules"
                      >
                      </v-text-field>
                     <v-btn @click="validate" block color="primary" elevation="8" rounded :disabled="!valid">Ingresar</v-btn>
                    
                  </v-col>   
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>        
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db } from "../../firebase";
import { doc, getDoc} from "firebase/firestore"
import { mapState, mapMutations } from 'vuex'

export default {
    name: 'Login',
    data() {
    return {
      valid: true,
      email: '',
      emailRules: [
        value => !!value || 'Requerido.',
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Formato de email inválido'
        },
      ],
    }
  },

  computed: {
    ...mapState(['newUser'])
  },

  methods: {
    ...mapMutations(['SET_USERKIN']),
    validate () {
      if(this.$refs.form.validate()){
        this.logInEmail(this.email)
      }
    },

    async logInEmail(email){
      try {
        const emailRef = doc(db, "custom/A101/userskin", email)
        const emailSnap = await (getDoc(emailRef))
        if(emailSnap.data()){
          const user = emailSnap.data()
          user.provider = 'email'
          this.$store.commit('SET_USERKIN', user)
          localStorage.setItem('userkin-laIslaMariscos', JSON.stringify(user))
        }
        else{
          //console.log("Userkin no encontrado")
          this.newUser.email = email
          this.$store.commit('SET_REGISTERUSERFLAG', true)
        }
      } catch (error) {
        console.log(error)
      }
    },
  },

}
</script>

<style>

</style>