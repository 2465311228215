import axios from "axios";

export default {
  methods: {
    async cf_getToken(){
      try {
        const token = await axios.get('https://anukin-notifications.herokuapp.com/cf_token')
        return token.data
      } catch (error) {
        console.log(error)
      }
    }

  },
}