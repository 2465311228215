<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <Login v-if="isAuth == false && registerUserFlag == false"/>
        <Signup v-if="registerUserFlag == true"/>
        <Tag v-if="isAuth == true" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Login from '../../components/laIslaMariscosLapizlazuli/Login.vue'
import Signup from '../../components/laIslaMariscosLapizlazuli/Signup.vue'
import Tag from '../../components/laIslaMariscosLapizlazuli/Tag.vue'

import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  components: {
    Login,
    Signup,
    Tag
  },
  data() {
    return {
      clientTag: {
        client: '',
        tag: ''
      },
    }
  },

  computed: {
    ...mapState(['userkin','tag','client','registerUserFlag']),
    isAuth(){
      return this.userkin != null ? true : false
    },
  },

  methods: {
    ...mapActions(['setClientTag']),
    ...mapMutations(['SET_USERKIN']),

    async getClientTag(){
      this.clientTag.client = 'A101'
      this.clientTag.tag = this.$route.params.tag
      //console.log(this.clientTag)
      this.setClientTag(this.clientTag)
    },

    async detectUserkinA101(){
      if(localStorage.getItem('userkin-laIslaMariscos')){
        this.$store.commit('SET_USERKIN', JSON.parse(localStorage.getItem('userkin-laIslaMariscos')))
      }
    },
  },

  async created() {
    await this.getClientTag()
    await this.detectUserkinA101()
  },



}
</script>

<style>

</style>