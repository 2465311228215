<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        
        <v-dialog v-model="dialog" max-width="350px" persistent>
          <v-card>
            <v-card-title class="text-center">
              <v-img src="../../assets/A101/logo.png" contain max-height="150"></v-img>
            </v-card-title>
            <v-card-text class="text-h6 text-center">
              ¡Hola {{name}}!<br>Tienes: <b>{{visitCount}}</b> visitas<br>
            </v-card-text>
            <v-card-text v-if="!winReward" class="text-left">
              <b>Recompensas:</b><br>
              * Visita 5: Platillo de $99<br>
              * Visita 10: Dos sushis clásicos<br>
              * Visita 15: Torre cura crudas<br>
              * Visita 20: Torre tres camarones<br>
              * Visita 25: Botella de tequila
            </v-card-text> 
            <v-card-text v-else class="text-center">
              <p class="text-h6"><b>¡GANASTE UNA RECOMPENSA!</b><p>
              <p>Click en continuar para obtenerla...</p>
            </v-card-text>  
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn color="primary" @click="close">Continuar</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>


      </v-col>
    </v-row>
  </v-container> 
</template>

<script>
import axios from "axios";
import { db } from "../../firebase";
import { Timestamp, addDoc, collection, doc, updateDoc, getDocs, getDoc, query, orderBy, limit, where} from "firebase/firestore"
import { mapActions, mapState } from 'vuex'
import cf_getToken from '../../mixins/clientify'


export default {
  name: 'Tag',
  data() {
    return {
      clientTag: {
        client: '',
        tag: ''
      },
      winReward: false,
      visitCount: 0, 
      dialog: false,
      lastVisit: null,
      promoGeneric: 'https://firebasestorage.googleapis.com/v0/b/anukin-ulink-prod-2022.appspot.com/o/A101%2Fstatic%2FsigueParticipando.jpg?alt=media&token=2bf9a6e5-a9ab-4707-8b91-984a55adf998',
      promo1: 'https://firebasestorage.googleapis.com/v0/b/anukin-ulink-prod-2022.appspot.com/o/A101%2Fstatic%2F1.png?alt=media&token=bc9c9ea6-f915-4f05-9a69-06fe2343c89b',
      promo5: 'https://firebasestorage.googleapis.com/v0/b/anukin-ulink-prod-2022.appspot.com/o/A101%2Fstatic%2F5.png?alt=media&token=47e1899e-d5c0-47dd-9e24-dd6e4b7e8344',
      promo10: 'https://firebasestorage.googleapis.com/v0/b/anukin-ulink-prod-2022.appspot.com/o/A101%2Fstatic%2F10.png?alt=media&token=63479355-9a33-4320-bfe4-6fcf9706733b',
      promo15: 'https://firebasestorage.googleapis.com/v0/b/anukin-ulink-prod-2022.appspot.com/o/A101%2Fstatic%2F15.png?alt=media&token=820f9057-b1ac-4808-b668-ab7856c1460e',
      promo20: 'https://firebasestorage.googleapis.com/v0/b/anukin-ulink-prod-2022.appspot.com/o/A101%2Fstatic%2F20.png?alt=media&token=9c8d3315-c380-494f-b796-6a1c5bd1f00c',
      promo25: 'https://firebasestorage.googleapis.com/v0/b/anukin-ulink-prod-2022.appspot.com/o/A101%2Fstatic%2F25.png?alt=media&token=d70e0a33-b219-41a4-9618-1eda95f099b5',
      promo: '',
    }
  },

  computed: {
    ...mapState(['tag', 'userkin']),
    name () {
      const myArray = this.userkin.name.split(' ')
      return myArray[0]
    }
  },

  mixins: [cf_getToken],

  methods: {
    ...mapActions(['setClientTag']),

    close () {
      this.dialog = false
      window.location.replace(this.promo)
    },

    async getLastVisit(){
      const q = query(collection(db, "custom/A101/interactions"), where("email", "==", this.userkin.email), orderBy("created", "desc"), limit(1))
      const querySnapshot = await getDocs(q)
      const myArray = []
      querySnapshot.forEach(item => {
        myArray.push(item.data())
      })
      if(myArray.length > 0){this.lastVisit = myArray[0].created.toDate()}
    },

    async registerVisit(){
      //Create visit Object and create interaction
      const visit = Object.assign({}, this.userkin, this.tag)
      visit.interaction = 'SCAN'
      visit.created = Timestamp.now()
      if(visit.birthday.seconds){visit.birthday = new Timestamp(visit.birthday.seconds, 0)}
      else{visit.birthday = null}
      delete visit.visits
      delete visit.enabled
      await addDoc(collection(db, "custom/A101/interactions"), visit)
      
      //Increase visit count
      const docRefVisit = doc(db, "custom/A101/userskin", visit.email)
      const docSnapVisit = await getDoc(docRefVisit)
      this.visitCount = docSnapVisit.data().visits + 1
      const ref = doc(db, "custom/A101/userskin", visit.email)
      await updateDoc(ref, {
        visits: this.visitCount
      })
      if (this.visitCount == 1 || this.visitCount == 5 || this.visitCount == 10 || this.visitCount == 15 || this.visitCount == 20 || this.visitCount == 25 ){
        this.winReward = true
      }
      this.cf_updateVisits(visit.email, this.visitCount)
    },

    async cf_updateVisits(email, visits){
      try {
        const token = await this.cf_getToken()
        const config = {
          headers:{
          "Authorization" : `Token ${token}`
          }
        }

        //Get userId from clientify
        const responseUserId = await axios.get(`https://api.clientify.net/v1/contacts/?query=${email}`, config)
        const userId = responseUserId.data.results[0].id

        //Update visits in clientify
        const responseUpdateContact = await axios.put(`https://api.clientify.net/v1/contacts/${userId}/`, {
          "custom_fields" : [{"field" : "Visitas", "value" : visits}]
        }, config)
        //console.log(responseUpdateContact)


      } catch (error) {
        console.log(error)
      }
      

    },

    validateVisit(){
      const today = new Date()
      today.setHours(0,0,0,0)
      if(this.lastVisit < today){
        return true
      } else {
        return false
      }
    },

    async myMain(){
      await this.getLastVisit()
      if(this.lastVisit == null){
        await this.registerVisit()
        //this.promo = JSON.parse(localStorage.getItem('urlkin'))
        this.promo = this.promo1
        this.dialog = true
      }
      else {
        const visit = this.validateVisit()
        //this.promo = this.promoGeneric
        if(visit == true){ 
          await this.registerVisit()
          if(this.visitCount == 1){this.promo = this.promo1}
          else if(this.visitCount == 5){this.promo = this.promo5}
          else if(this.visitCount == 10){this.promo = this.promo10}
          else if(this.visitCount == 15){this.promo = this.promo15}
          else if(this.visitCount == 20){this.promo = this.promo20}
          else if(this.visitCount == 25){this.promo = this.promo25}
          else {this.promo = JSON.parse(localStorage.getItem('urlkin'))}
          this.dialog = true
        } else {
          window.location.replace(JSON.parse(localStorage.getItem('urlkin')))
        }

        
        
      }
    },


  },

  async mounted() {
    this.clientTag.client = "A101"
    this.clientTag.tag = this.$route.params.tag
    await this.setClientTag(this.clientTag)
    //await this.goToUrl()
    await this.myMain()
  },

}
</script>