<template>
  <v-container>
    <v-row class="justify-center" >
      <v-col cols="12" md="6">
        <v-card class="elevation-12 text-center rounded-xl" color="#F0F1FF">
          <v-card-title class="mt-2">
            <v-img src="../../assets/A101/logo.png" contain max-height="150"></v-img>
            
          </v-card-title>
          <v-card-subtitle class="mt-2">
            <h3>Completa tu registro:</h3>
          </v-card-subtitle>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-container>
                <v-row>
                  <v-col cols="12">
                      <v-text-field 
                        v-model="newUser.email"
                        label="Email" 
                        filled
                        outlined 
                        dense
                        prepend-icon="mdi-at"
                        :rules="emailRules"
                      >
                      </v-text-field>
                      <v-text-field 
                        v-model="newUser.name"
                        label="Nombre" 
                        filled
                        outlined 
                        dense
                        prepend-icon="mdi-account"
                        :rules="[v => !!v || 'Nombres es requerido']"
                      >
                      </v-text-field>
                      <div>
                        <!--<div class="mb-6">Active picker: <code>{{ activePicker || 'null' }}</code></div>-->
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >

                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              filled
                              outlined 
                              dense
                              v-model="newUser.birthday"
                              label="Fecha de nacimiento"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :rules="[v => !!v || 'Fecha es requerdio']"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            no-title
                            v-model="newUser.birthday"
                            :active-picker.sync="activePicker"
                            :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @change="save"
                            locale="es-mx"
                          ></v-date-picker>
                        </v-menu>
                      </div>                    

                  </v-col>
                     <v-btn @click="validate" block color="primary" elevation="8" rounded :disabled="!valid">Ingresar</v-btn>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          
                 
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { db } from "../../firebase";
import { doc, setDoc, getDoc, Timestamp} from "firebase/firestore"
import { mapState, mapMutations, mapActions } from 'vuex'
import cf_getToken from '../../mixins/clientify'


export default {
  name: 'Signup',
  data() {
    return {
      valid: true,
      emailRules: [
        value => !!value || 'Requerido.',
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Formato de email inválido'
        },
      ],
      
      activePicker: null,
      menu: false,
    }
  },

  computed: {
    ...mapState(['newUser'])
  },

  mixins: [cf_getToken],

  methods: {
    ...mapActions(['sendWelcomeEmail']),
    ...mapMutations(['SET_USERKIN', 'SET_REGISTERUSERFLAG', 'CLEAR_NEWUSER']),

    async registerUser(newUser){
      try {
        const dateJS = new Date(newUser.birthday)
        const userTimezoneOffset = dateJS.getTimezoneOffset() * 60000
        const timestamp = new Date(dateJS.getTime() + userTimezoneOffset)
        await setDoc(doc(db, "custom/A101/userskin", newUser.email), {
          name: newUser.name,
          email: newUser.email,
          birthday: Timestamp.fromDate(timestamp),
          visits: 0
        })

        //Send Welcome Email
        this.sendWelcomeEmail(newUser)

        //Register in clientify
        const token = await this.cf_getToken()
        this.cf_registerUser(token, newUser.name, newUser.email, newUser.birthday)

        //Login and clean
        await this.logInEmail(newUser.email)
        this.$store.commit('SET_REGISTERUSERFLAG', false)
        this.$store.commit('CLEAR_NEWUSER', {})
      } catch (error) {
        console.log(error)
      }
    },

    async logInEmail(email){
      try {
        const emailRef = doc(db, "custom/A101/userskin", email)
        const emailSnap = await (getDoc(emailRef))
        const user = emailSnap.data()
        user.provider = 'email'
        this.$store.commit('SET_USERKIN', user)
        localStorage.setItem('userkin-laIslaMariscos', JSON.stringify(user))
      } catch (error) {
        console.log(error)
      }
    },

    async cf_registerUser(token, name, email, birthday){
      try {
        const config = {
          headers:{
            "Authorization" : `Token ${token}`
          }
        }
        const response = await axios.post('https://api.clientify.net/v1/contacts/', {
          "first_name" : name,
          "email" : email,
          "birthday" : birthday
        }, config)
        //console.log(response)
      } catch (error) {
        console.log(error)
      }
      
    },
    
    save (date) {
        this.$refs.menu.save(date)
    },

    validate () {
        if(this.$refs.form.validate()){
          this.registerUser(this.newUser)
        }
    },
  },

  watch: {
      menu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
  },

}
</script>
